import { AppProps } from 'react-commons';
import { useEffectOnce } from 'react-commons';
import { authActions, authActionSets, useAuthStore } from '@/lib/drupal/stores/auth';

export function useDrupalAuth (onVerify?: AppProps['onAuthVerify'], onError?: AppProps['onAuthError']) {
  const [ authState, authDispatch, authExecute ] = useAuthStore();

  useEffectOnce(
    () => {
      (async () => {
        if (authState.auth) {
          await authExecute(authActionSets.verify({ onError }));
          if (onVerify) onVerify();
        } else {
          authDispatch(authActions.setReady(true));
        }
      })();
    }, 
    () => {
      if (!authState.auth && !authState.user) {
        return true;
      }
    },
    [ authState.auth ]
  );
}
