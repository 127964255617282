import { MockConfig } from '@/lib/util/mockRequest';
import { getZonedDate } from '@/lib/util/zonedTime';

const config: MockConfig = {
  options: {
    maxNumItemsTotal: 99,
    maxNumItemsPerRequest: 10,
    latency: 50,
    failureRate: 0,
    failureStatusCodeWeights: [
      {
        statusCode: [ 400, 403 ],
        weight: 1,
      },
      {
        statusCode: 404,
        weight: 1,
      },
      {
        statusCode: 500,
        weight: 1,
      },
    ],
    defaultErrorReturnValue: () => ({ ok: false }),
    parameterKeys: {
      page: 'page',
      limit: 'limit',
      offset: 'offset',
    }
  },
  
  generators: {
    $id (index, _args, _params, { random }) {
      return ((index + 1) + random().toString().replace('.', '')).slice(0, 16);
    },

    $image (_index, args) {
      const [
        width,
        height
      ] = args;

      return `https://placekitten.com/${width || 64}/${height || 64}`;
    },

    $text (_index, args, _params, { random }) {
      const maxLength = isNaN(parseInt(args[ 0 ])) 
        ? undefined 
        : parseInt(args[ 0 ]);

      const sourceText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean auctor nec odio sit amet mollis. In volutpat sodales placerat. In luctus dictum sem, sed auctor arcu elementum eu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Etiam quis feugiat felis. Integer non gravida nulla, nec ornare odio. Duis iaculis elementum justo, ut dignissim lorem venenatis ut. Donec ultricies sapien a faucibus cursus. Praesent fringilla gravida lobortis';
      const sourceLines = sourceText.split('. ');

      // Shuffle the lines
      for (let i = sourceLines.length - 1; i > 0; i--) {
        const j = Math.floor(random() * ( i + 1 ));
        [ 
          sourceLines[ i ], 
          sourceLines[ j ] 
        ] = [ 
          sourceLines[ j ], 
          sourceLines[ i ] 
        ];
      }

      if (maxLength) {
        let text = '';
        let i = 0;
        while (text.length < maxLength) {
          text += sourceLines[ i % (sourceLines.length - 1) ] + '. ';
          i++;
        }
        return text
          .trim()
          .substring(0, maxLength - 3)
          .trim()
          .concat('...');
      }

      return sourceLines
        .join('. ')
        .concat('.');
    },

    $numberRange (_index, args, _params, { randomRange }) {
      return randomRange(parseInt(args[ 0 ]), parseInt(args[ 1 ]));
    },

    $roundedRange (_index, args, _params, { randomRange }) {
      return Math.round(randomRange(parseInt(args[ 0 ]), parseInt(args[ 1 ])));
    },

    $date (_index, _args, _params, { randomRange }) {
      const fromDate = getZonedDate();
      fromDate.setMonth(0);
    
      const toDate = getZonedDate();
      toDate.setMonth(11);
    
      return getZonedDate(randomRange(fromDate.getTime(), toDate.getTime())).getTime();
    },

    $slug (_0, _1, _2, { randomRange }) {
      const fakeGameSlugs = [
        'shockwave-original-spot-the-difference-daily',
        'daily-word-search-plus',
        'mahjongg',
        'daily-wordup',
        'daily-crossup-isometrics',
        'daily-spot-the-turkey',
        'mafia-poker',
        'animal-merge',
        'pirate-cards',
        'stickman-swing',
        'wind-and-solar',
        'fruit-crush-frenzy',
        'daily-jigsaw',
      ];
      const slug = fakeGameSlugs[ Math.round(randomRange(0, fakeGameSlugs.length - 1)) ];
      return '/gamelanding/mock-game-' + slug;
    },
    
    $gameThumbnail (_index, _args, _params, { randomRange }) {
      const thumbnails = [
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/shockwave-original-spot-the-difference-daily_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-word-search-plus-icons_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/mahjongg_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-wordup_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-crossup-isometrics_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-spot-the-turkey_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/mafia-poker_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/animal-merge_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/pirate-cards_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/stickman-swing_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/wind-and-solar_regular.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/fruit-crush-frenzy_regular.jpg',
      ];
    
      return thumbnails[ Math.round(randomRange(0, thumbnails.length - 1)) ];
    },
    
    $gameThumbnailSmall (_index, _args, _params, { randomRange }) {
      const thumbnails = [
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/shockwave-original-spot-the-difference-daily_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-word-search-plus-icons_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/mahjongg_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-wordup_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-crossup-isometrics_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-spot-the-turkey_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/mafia-poker_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/animal-merge_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/pirate-cards_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/stickman-swing_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/wind-and-solar_small.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/fruit-crush-frenzy_small.jpg',
      ];
    
      return thumbnails[ Math.round(randomRange(0, thumbnails.length - 1)) ];
    },
    
    $gameThumbnailTall (_index, _args, _params, { randomRange }) {
      const thumbnails = [
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/shockwave-original-spot-the-difference-daily_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-word-search-plus-icons_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/mahjongg_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-wordup_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-crossup-isometrics_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/daily-spot-the-turkey_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/mafia-poker_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/animal-merge_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/pirate-cards_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/stickman-swing_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/wind-and-solar_large.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/fruit-crush-frenzy_large.jpg',
      ];
    
      return thumbnails[ Math.round(randomRange(0, thumbnails.length - 1)) ];
    },
    
    $gameThumbnailXl (_index, _args, _params, { randomRange }) {
      const thumbnails = [
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/whispered-secrets-morbid-obsession-ce_xl.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/spirit-legends-finding-balance-se_xl.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/terrafarmers_xl.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/shopping-clutter-19_xl.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/owl-rescue_xl.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/strange-investigations-becoming-se_xl.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/dark-asylum-mystery-adventure_xl.jpg',
        'https://www.shockwave.com/sites/default/files/_GAME_IMAGES/picons/jolly-gangs-spooky-adventures_xl.jpg',
      ];
    
      return thumbnails[ Math.round(randomRange(0, thumbnails.length - 1)) ];
    },

    $gameFeaturedImg (_index, _args, _params, { randomRange }) {
      const thumbnails = [
        'https://www.shockwave.com/sites/default/files/styles/dynamic_feature/public/_GAME_FILES/dynamic_feature/flower-mahjong-solitaire_dynamic_feature.jpg',
        'https://www.shockwave.com/sites/default/files/styles/dynamic_feature/public/_GAME_FILES/dynamic_feature/whispered-secrets-morbid-obsession-ce_dynamic_feature.jpg',
        'https://www.shockwave.com/sites/default/files/styles/dynamic_feature/public/_GAME_FILES/dynamic_feature/terrafarmers_dynamic_feature.jpg',
        'https://www.shockwave.com/sites/default/files/styles/dynamic_feature/public/_GAME_IMAGES/dynamicFeatures/daily-jigsaw-space_dynamic_feature_0.jpg',
        'https://www.shockwave.com/sites/default/files/styles/dynamic_feature/public/_GAME_IMAGES/dynamicFeatures/shockwave-original-daily-jigsaw-puzzler_dynamic_feature.jpg',
        'https://www.shockwave.com/sites/default/files/styles/dynamic_feature/public/_GAME_IMAGES/dynamicFeatures/daily-crossup-isometrics-bonus_dynamic_feature.jpg',
        'https://www.shockwave.com/sites/default/files/styles/dynamic_feature/public/_GAME_FILES/dynamic_feature/rabbit-run_dynamic_feature.jpg',
        'https://www.shockwave.com/sites/default/files/styles/dynamic_feature/public/_GAME_FILES/dynamic_feature/jewel-duel_dynamic_feature.jpg',
      ];
    
      return thumbnails[ Math.round(randomRange(0, thumbnails.length - 1)) ];
    }
  }
};
export default config;
