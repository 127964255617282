import Image from 'next/image';
import { createComponent } from 'react-commons';
import style from './index.module.scss';

export default createComponent('BackgroundImage', { style }, function BackgroundImage ({ className }, props) {
  return (
    <div className={className} aria-hidden='true'>
      <Image 
        alt='Background image'
        src={'/images/background.jpg'}
        layout='fill'
        objectFit='cover'
        objectPosition='center'
      />
    </div>
  );
});
