import '@/styles/app.scss';
import '@/styles/themes.scss';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/700.css';
// import '@/styles/dev.scss';

import pkg from '@/package.json';

import { App, Modals, Notifications, setConfig, ComScore } from 'react-commons';
import { AuthProvider } from '@/lib/drupal/stores/auth';
import { AppStoreProvider } from '@/lib/stores/app';
import { useDrupalAuth } from '@/lib/drupal/hooks/useDrupalAuth';
import { ThemeStoreProvider, useThemeStore } from '@/lib/stores/theme';
import { RecentlyPlayedProvider } from '@/lib/stores/recentlyPlayed';
import BackgroundImage from '@/components/BackgroundImage';

function AppInner ({ Component, pageProps }) {
  const [ themeState ] = useThemeStore();

  return (
    <App
      theme={themeState.theme}
      authHook={useDrupalAuth}
      googleAnalyticsOptions={{
        uakey: process.env.APP_CONFIG.GOOGLE_UA_ID,
        ga4key: process.env.APP_CONFIG.GOOGLE_GA4_ID,
      }}
    >
      <ComScore clientId={process.env.APP_CONFIG.COMSCORE_CLIENT_ID} />
      <Modals />
      <Notifications />
      <Component {...pageProps} />
    </App>
  );
}

function _app ({ Component, pageProps }) {
  setConfig({
    APP_DESCRIPTION: process.env.APP_CONFIG.DESCRIPTION,
    APP_KEYWORDS: process.env.APP_CONFIG.KEYWORDS,
    APP_SEARCH_URL: process.env.APP_CONFIG.SEARCH_URL,
    APP_THEME_COLOR: process.env.APP_CONFIG.THEME,
    APP_TITLE: process.env.APP_CONFIG.APP_TITLE,
    APP_TITLE_TEMPLATE: process.env.APP_CONFIG.TITLE_TEMPLATE,
    BASE_URL: process.env.APP_CONFIG.URL,
    COMSCORE_CLIENT_ID: process.env.APP_CONFIG.COMSCORE_CLIENT_ID,
    IDENTIFIER: pkg.identifier,
    NODE_ENV: process.env.NODE_ENV,
    PACKAGE_NAME: pkg.name,
    REPORT_LOG_ON_LEVELS: 'error,fatal'
  });

  return (
    <App.Provider
      authProvider={AuthProvider}
    >
      <AppStoreProvider>
        <ThemeStoreProvider>
          <RecentlyPlayedProvider>
            <BackgroundImage />
            <AppInner
              Component={Component}
              pageProps={pageProps}
            />
          </RecentlyPlayedProvider>
        </ThemeStoreProvider>
      </AppStoreProvider>
    </App.Provider>
  );
}

export default _app;
