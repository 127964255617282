import axios, { AxiosResponse } from 'axios';
import config from '@/mock.config';
import { seededRandom } from 'react-commons';

export type MockParams = {
  [key: string]: string
  __schema: string
}

export type MockArgs = string[] | null

export type MockSchema = {
  [key: string]: any
}

export type MockResponse = {
  error?: { 
      statusCode: number 
      error: any
  }
  response?: { 
      [key: string]: any 
  }
}

export type MockConfig = {
  options: {
      maxNumItemsTotal: number
      maxNumItemsPerRequest: number
      latency: number
      failureRate: number
      failureStatusCodeWeights: { 
          statusCode: number | [number, number]
          weight: number
      } []
      defaultErrorReturnValue: () => any
      parameterKeys: {
          page: string
          limit: string
          offset: string
      }
  }
  
  generators: {
      [key: string]: (
          index: number, 
          args: MockArgs, 
          params: MockParams,
          helpers: {
              random: () => number, 
              randomRange: (min: number, max: number) => number
          }
      ) => string | number
  }
}

function getSeed (str: string) {
  const seed = [];
  for (let i = 0; i < str.length; i++) {
    seed.push(str.charCodeAt(i));
  }
  return seed.reduce((acc, value) => acc + value, 1);
}

function getRandomStatusCode (weights?: MockConfig['options']['failureStatusCodeWeights']) {
  if (!weights) return 500;

  const pool: number[] = weights.reduce<number[]>((acc, item) => {
    const items: number[] = [];
    while (items.length < item.weight * 100) {
      if (Array.isArray(item.statusCode)) {
        items.push(item.statusCode[ Math.round(Math.random() * (item.statusCode.length - 1)) ]);
      } else {
        items.push(item.statusCode);
      }
    }
    return [ ...acc, ...items ];
  }, []);

  return pool[ Math.round(Math.random() * (pool.length - 1)) ];
}

function parseSchema (
  schema: MockSchema | string, 
  params: MockParams, 
  config: MockConfig, 
  numResults: number,
  seed: number,
  result: { [key: string]: any } = {}, 
  currentKey: string = '', 
  currentIndex: number = 0
) {
  if (Array.isArray(schema)) {
    let nextResult: any;

    if (Array.isArray(result)) nextResult = result[ currentIndex ] = {};
    if (currentKey) nextResult = result[ currentKey ] = [];
    else nextResult = result = [];

    if (!schema.length) return;

    const item = schema[ 0 ];
    const howMany = item.__qty || numResults || 1;

    nextResult.length = howMany;
    nextResult.fill(null);
    nextResult.forEach((_value: null, index: number) => parseSchema(item, params, config, numResults, seed, nextResult, currentKey, index));
  }

  else if (typeof schema === 'object') {
    let nextResult: any;

    if (Array.isArray(result)) nextResult = result[ currentIndex ] = {};
    else if (currentKey) nextResult = result[ currentKey ] = {};
    else nextResult = result = {};

    Object
      .entries(schema)
      .forEach(([ key, value ]) => {
        parseSchema(value, params, config, numResults, seed, nextResult, key, currentIndex);
      });
  }

  else {
    let item: any = schema;

    if (typeof item === 'string' && item.startsWith('$')) {
      const [ random, randomRange ] = seededRandom(currentIndex + seed);

      // throw away the first few random values to get more variety
      random();
      randomRange(0, 1);

      const helpers = {
        random, 
        randomRange
      };

      const nameFound = item.match(/^\$[a-zA-Z_-\d]+/gi);
      if (!nameFound) throw new Error('Could not parse generator name.');

      const generator = config.generators[ nameFound[ 0 ] ];
      if (!generator) throw new Error('Generator not found.');

      const argsFound = item.match(/(\(.+\))$/gi);
      const args = argsFound 
        ? argsFound[ 0 ]
          .replace(/[\(\)]/gi, '')
          .split(',')
          .map((str) => str.trim())
        : [];
      item = generator(currentIndex, args, params, helpers);
    }

    if (Array.isArray(result)) {
      result[ currentIndex ] = item;
    } else {
      result[ currentKey ] = item;
    }
  }

  return result;
}

export function getResponse (params: MockParams, config: MockConfig): MockResponse {
  const failureRate = config.options.failureRate;
  if (Math.random() <= failureRate) {
    return {
      error: {
        statusCode: getRandomStatusCode(config.options.failureStatusCodeWeights),
        error: config.options.defaultErrorReturnValue
          ? config.options.defaultErrorReturnValue()
          : { ok: false }
      }
    };
  }

  let schema: MockSchema;
  try {
    schema = JSON.parse(params.__schema);
  } catch (err) {
    return {
      error: {
        statusCode: -1,
        error: err
      }
    };
  }

  let page = parseInt(params[ config.options.parameterKeys.page ]);
  const hasPage = !isNaN(page);
  page = hasPage ? page : 0;

  let limit = parseInt(params[ config.options.parameterKeys.limit ]);
  const hasLimit = !isNaN(limit);
  limit = hasLimit ? limit : config.options.maxNumItemsPerRequest;

  let offset = parseInt(params[ config.options.parameterKeys.offset ]);
  const hasOffset = !isNaN(offset);
  offset = hasOffset ? offset : 0;
  
  let numItems = 1;
  let startingIndex = offset;

  if (hasPage) {
    numItems = limit;
    startingIndex = page * limit + offset;
  }
  else if (hasLimit || hasOffset) {
    numItems = limit;
    startingIndex = offset;
  } 

  const maxNumItems = config.options.maxNumItemsTotal || 0;
  if (maxNumItems) {
    if (numItems + startingIndex > maxNumItems) {
      numItems = (numItems + startingIndex) - maxNumItems; 
    }
  }
  numItems = Math.max(1, numItems);

  const seed = getSeed(JSON.stringify(params));

  return {
    response: parseSchema(schema, params, config, numItems, seed)
  };
}

function stagingHandler (query) {
  const result = getResponse(query, config);
  const response: AxiosResponse = {
    data: null,
    status: -1,
    statusText: '',
    headers: {},
    config: {},
  };

  if (result.error) {
    response.status = result.error.statusCode;
    response.statusText = result.error.error;
  } else {
    response.data = result.response;
    response.status = 200;
    response.statusText = 'OK';
  }

  return response;
}

export async function mockGet (schema: any, limit?: number, offset = 0) {
  if (process.env.NEXT_HOST_ENV === 'staging') {
    return stagingHandler({ __schema: JSON.stringify(schema), limit, offset });
  }

  try {
    const schemaJson = JSON.stringify(schema);
    const url = limit === undefined 
      ? `/api/mock?__schema=${schemaJson}`
      : `/api/mock?__schema=${schemaJson}&limit=${limit}&offset=${offset}`;

    return await axios.get(process.env.NEXT_PUBLIC_BASE_URL + url);
  } catch (err) {
    throw err;
  }
}

export async function mockPost (schema: any, limit?: number, offset = 0) {
  if (process.env.NEXT_HOST_ENV === 'staging') {
    return stagingHandler({ __schema: JSON.stringify(schema), limit, offset });
  }

  try {
    const schemaJson = JSON.stringify(schema);
    const url = '/api/mock';

    const data = {
      __schema: schemaJson,
    };
    if (limit !== undefined) Object.assign(data, { limit, offset });

    return await axios.post(process.env.NEXT_PUBLIC_BASE_URL + url, data);
  } catch (err) {
    throw err;
  }
}
